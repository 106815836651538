<template>
    <div
        style="width: 100%"
        class="kits-leave-absence"
    >
        <div class="text-l">
            <div>-------------------------------------------</div>
            <div>
                学生评价班主任的内容开始位置（该文字信息不会显示在表单填写中）
            </div>
            <div>---------------------------------------------</div>
        </div>
        <div class="main">
            <el-form
                ref="form"
                label-position="top"
            >
                <div
                    v-for="(item, index) of children"
                    :key="index"
                    class="ritem"
                >
                    <div
                        class="ritem_top"
                        :class="{isActive: index === selsect}"
                        @click="itemBtn(index, item)"
                    >
                        <el-form-item
                            :label-width="item.labelWidth + 'px'"
                            :label="item.label"
                        >
                            <el-radio-group v-model="item.value">
                                <el-radio
                                    style="display: block"
                                    :label="val.value"
                                    v-for="(val, key) of item.options"
                                    :key="key"
                                    >{{ val.label }}</el-radio
                                >
                            </el-radio-group>
                        </el-form-item>
                    </div>
                    <div class="cord">
                        <div
                            class="dicon dcopy"
                            @click="copy(item, children)"
                        ></div>
                        <div
                            v-if="index !== 0"
                            class="dicon ddelete"
                            @click="deleteItem(item, children)"
                        ></div>
                    </div>
                </div>
            </el-form>
        </div>

        <div class="text-l">
            <div>-------------------------------------------</div>
            <div>
                学生评价班主任的内容结束位置（该文字信息不会显示在表单填写中）
            </div>
            <div>---------------------------------------------</div>
        </div>
    </div>
</template>
<script>
import {mapState} from "vuex";
const kitName = "EvaluateTeachersBan";
export default {
    name: "EvaluateTeachersBan",
    props: ["compName", "children", "selecItem"],
    data() {
        return {
            list: [],
            selsect: "",
        };
    },
    computed: {
        ...mapState({
            schoolId: (state) => state.schoolId,
        }),
    },
    created() {
        this.$eventBus.$on("EvaluateTeachersBan", () => {
            this.selsect = "";
        });
        this.init();
    },
    beforeDestroy() {
        this.$eventBus.$off("EvaluateTeachersBan");
    },
    mounted() {},
    methods: {
        /**
         * @Description: 初始化
         * @Author: 周浩
         * @Date: 2024-8-7 13:53:16
         */
        init() {
            this.list = this.children;
        },
        /**
         * @Description: 按钮点击事件
         * @Author: 周浩
         * @Date: 2024-8-7 14:53:42
         */
        itemBtn(index, item) {
            this.selsect = index;
            this.$eventBus.$emit("transmission", {type: "active", data: index});
        },
        /**
         * @Description: 复制
         * @Author: 周浩
         * @Date: 2024-8-7 14:53:42
         */
        copy(item, children) {
            children.push(JSON.parse(JSON.stringify(item)));
            this.$eventBus.$emit("transmission", {
                type: "copy",
                comName: "EvaluateTeachersBan",
                data: children,
            });
        },
        /**
         * @Description: 删除
         * @Author: 周浩
         * @Date: 2024-8-7 14:53:42
         */
        deleteItem(index, children) {
            children.splice(index, 1);
            this.$eventBus.$emit("transmission", {
                type: "delete",
                comName: "EvaluateTeachersBan",
                data: children,
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.title {
    box-sizing: border-box;
    padding: 0 20px;
    display: flex;
    align-items: center;
    .transfer-icon {
        width: 11px;
        height: 11px;
        margin-right: 6px;
        display: inline-block;
    }
    .label {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #2b2f33;
    }
}

.el-form-item {
    padding-right: 0 !important;
}
.text-l {
    width: 100%;
    display: flex;
    overflow: hidden;
    justify-content: center;
    div {
        color: #999999;
        overflow: hidden;
        box-sizing: border-box;
    }
    & > div:nth-of-type(1) {
        // 超出文字使用...
        flex: 1;
        overflow: hidden;
        white-space: nowrap;
    }
    & > div:nth-of-type(3) {
        // 超出文字使用...
        flex: 1;
        overflow: hidden;
        white-space: nowrap;
    }
    & > div:nth-of-type(2) {
        width: fit-content;
        flex-shrink: 0;
    }
}

.main {
    display: flex;
    flex: 1;
    flex-direction: column;
    & > div {
        border: 1px solid black;
        width: 75%;
    }
    .ritem {
        padding-bottom: 10px;
    }
    .ritem_top {
        border: 1px solid #0000;
    }
    .ritem:hover .ritem_top {
        border: 1px dashed #3c7fff;
    }
    .ritem:hover {
        .ritem_top {
            border: 1px dashed #3c7fff;
        }
        & > .cord {
            display: flex;
        }
    }
    .ritem {
        position: relative;
    }
    .cord {
        display: flex;
        position: absolute;
        right: 2px;
        bottom: -10px;
        display: none;
        background: #ffffff;
        box-shadow: 0 5px 6px 0 #e1e3e6;
        border-radius: 10px;
        overflow: hidden;
        z-index: 9999;

        .dicon {
            cursor: pointer;
            width: 28px;
            height: 20px;
            line-height: 20px;
            text-align: center;
        }

        .dcopy {
            &::before {
                content: url("~@/assets/images/campusOA/copy.png");
            }
            &::after {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 1px;
                height: 12px;
                background: #e1e3e6;
                border-radius: 0 1px 1px 0;
            }
            &:hover {
                background-color: #ebf2ff;
                &::before {
                    content: url("~@/assets/images/campusOA/copy_.png");
                }
            }
        }

        .ddelete {
            &::before {
                content: url("~@/assets/images/campusOA/delete.png");
            }
            &:hover {
                background-color: #fcede8;
                &:before {
                    content: url("~@/assets/images/campusOA/delete_.png");
                }
            }
        }
    }
}
.isActive {
    background-color: #e6f2ff;
}
::v-deep{
    .el-radio__label{
        white-space: normal;
    }
}
</style>
